"use client";

import { BusinessJukeboxIcon, PersonalJukeboxIcon, PlusCircleIcon } from "@components/icons";
import Link from "next/link";
import { type MouseEventHandler } from "react";
import styles from "./JukeboxButton.module.css";
import { LoadingIcon } from "@components/custom";

interface Props {
  disabled?: boolean;
  href?: string;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  variant: "business" | "personal" | "create" | "redirect" | "button";
}

export default function JukeboxButton({
  disabled = false,
  href = "",
  onClick = undefined,
  isLoading = false,
  variant,
}: Props) {
  return (
    <button className={styles.jukebox_button} disabled={disabled} onClick={onClick}>
      {variant === "business" && (
        <Link className="w-fit" href="/get-started/signup/business">
          <BusinessJukeboxIcon className="w-24 h-24 sm:w-36 sm:h-36" />
          <h5>Business</h5>
        </Link>
      )}
      {variant === "personal" && (
        <Link className="w-fit" href="/get-started/signup/personal">
          <PersonalJukeboxIcon className="w-24 h-24 sm:w-36 sm:h-36 stroke-none" />
          <h5>Personal</h5>
        </Link>
      )}
      {variant === "create" && (
        <div className="w-fit">
          {isLoading ? (
            <LoadingIcon />
          ) : (
            <PlusCircleIcon className="w-24 h-24 sm:w-40 sm:h-40 !stroke-white" />
          )}
        </div>
      )}
      {variant === "redirect" && (
        <div className="w-fit">
          {isLoading ? (
            <LoadingIcon />
          ) : (
            <Link className="w-fit" href={href}>
              <PersonalJukeboxIcon className="w-24 h-24 sm:w-36 sm:h-36 stroke-none" />
            </Link>
          )}
        </div>
      )}
      {variant === "button" && (
        <div className="w-fit">
          {isLoading ? (
            <LoadingIcon />
          ) : (
            <PersonalJukeboxIcon className="w-24 h-24 sm:w-36 sm:h-36 stroke-none" />
          )}
        </div>
      )}
    </button>
  );
}
