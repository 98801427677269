"use client";

import { useAuth } from "@clerk/nextjs";
import { SpotifyIcon } from "@components/icons";
import { createAuthorizationURI, dateToFormattedISOString } from "@lib/functions";
import { createSpotifyToken } from "@lib/requests";
import { CLIENT_ID } from "@rocola/core/spotify";
import type * as SpotifyTypes from "@rocola/core/typings/spotify/index";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";

export default function ConnectSpotifyButton({
  searchParams,
  variant,
}: {
  searchParams: { [key: string]: string | string[] | undefined };
  /** Guest redirect is to spotbox.live, host redirect is to '/home' route. */
  variant: "guest" | "host";
}) {
  const router = useRouter();
  const { getToken, userId: clerkUserId } = useAuth();
  const [redirectUriHome, setRedirectUriHome] = useState("https://rocola.it/home");
  const [redirectUriGuest, setRedirectUriGuest] = useState("https://rocola.it");

  useEffect(() => {
    setRedirectUriHome(window.location.origin + "/home");
    setRedirectUriGuest(window.location.origin);
  }, []);

  useEffect(() => {
    const createTokens = async (code: string, userId: string) => {
      const codeVerifier = sessionStorage.getItem("spotify_code_verifier");
      const state = sessionStorage.getItem("spotify_auth_state");

      if (codeVerifier && state) {
        const tokenClerk = await getToken({ template: "aws" });
        if (tokenClerk && variant === "host") {
          await createSpotifyToken(
            userId,
            tokenClerk,
            code,
            codeVerifier,
            window.location.origin,
            state,
          );
        } else {
          const response = await fetch("https://accounts.spotify.com/api/token", {
            body: new URLSearchParams({
              client_id: CLIENT_ID,
              code,
              code_verifier: codeVerifier,
              grant_type: "authorization_code",
              redirect_uri: redirectUriGuest,
            }),
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
          });

          const data = (await response.json()) as
            | SpotifyTypes.AccessToken
            | { error: string; error_description: string };

          if (!("error" in data) && !("error_description" in data)) {
            const {
              access_token: accessToken,
              refresh_token: refreshToken,
              expires_in: expiresIn,
            } = data;

            const expiresAt = dateToFormattedISOString(
              new Date(Date.now() + expiresIn * 1000 - 20),
            );

            window.sessionStorage.setItem("spotify_access_token", String(accessToken));
            window.sessionStorage.setItem("spotify_refresh_token", String(refreshToken));
            window.sessionStorage.setItem("spotify_expiration", expiresAt);
          }
        }
      }
      window.sessionStorage.removeItem("spotbox-email");
      window.sessionStorage.removeItem("spotbox-password");
      window.sessionStorage.removeItem("spotbox-confirmation-code");
      window.sessionStorage.removeItem("spotify_code_verifier");
      window.sessionStorage.removeItem("spotify_auth_state");
      router.replace(variant === "host" ? "/home" : "/");
    };

    if (variant === "host") {
      const { code } = searchParams;
      if (code && typeof searchParams.code === "string" && clerkUserId) {
        createTokens(searchParams.code, clerkUserId);
      }
    }
  }, [clerkUserId, getToken, redirectUriGuest, router, searchParams, variant]);

  return (
    <button
      className="button "
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        if (window !== undefined) {
          event.preventDefault();

          const { destination, codeVerifier, code_challenge, state } = createAuthorizationURI(
            CLIENT_ID,
            variant === "host" ? redirectUriHome : redirectUriGuest,
          );

          const storedCodeVerifier = sessionStorage.getItem("spotify_code_verifier");
          const storedAuthState = sessionStorage.getItem("spotify_auth_state");

          if (
            (!storedCodeVerifier && codeVerifier) ||
            (storedCodeVerifier && storedCodeVerifier !== codeVerifier)
          ) {
            sessionStorage.setItem("spotify_code_verifier", codeVerifier);
            sessionStorage.setItem("code_challenge", code_challenge);
          }

          if ((!storedAuthState && state) || (storedCodeVerifier && storedAuthState !== state)) {
            sessionStorage.setItem("spotify_auth_state", state);
          }

          window.location.replace(destination);
        }
      }}
    >
      <SpotifyIcon className="button_icon mx-2" />
      <p>Link Spotify</p>
    </button>
  );
}
