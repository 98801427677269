"use client";

import { useAuth } from "@clerk/nextjs";
import { dateToFormattedISOString } from "@lib/functions";
import { createSpotifyToken } from "@lib/requests";
import { CLIENT_ID } from "@rocola/core/spotify";
import type * as SpotifyTypes from "@rocola/core/typings/spotify/index";
import { useRouter, useSearchParams } from "next/navigation";
import { ReactNode, useEffect, useState } from "react";

export default function SpotifyCredentialManager({
  children,
  variant,
}: {
  children?: ReactNode;
  variant: "HOST" | "GUEST";
}) {
  const [redirectUri, setRedirectUri] = useState("https://rocola.it");
  const searchParams = useSearchParams();
  const { getToken, userId: clerkUserId } = useAuth();
  const router = useRouter();

  useEffect(() => {
    setRedirectUri(window.location.origin);
  }, []);

  useEffect(() => {
    if (variant !== "HOST") {
      return;
    }

    const authCode = searchParams.get("code");
    const authState = window.sessionStorage.getItem("spotify_auth_state");
    const spotifyAuthError = searchParams.get("error");
    const codeVerifier = window.sessionStorage.getItem("spotify_code_verifier");

    if (spotifyAuthError) {
      // TODO: Handle error logging here, if needed.
      return;
    } else if (!authCode) {
      return;
    } else if (!authState) {
      return;
    } else if (!codeVerifier) {
      return;
    } else if (!clerkUserId) {
      return;
    }

    getToken({ template: "aws" })
      .then((clerkToken) =>
        createSpotifyToken(clerkUserId, clerkToken, authCode, codeVerifier, redirectUri, authState),
      )
      .finally(() => {
        sessionStorage.removeItem("spotbox-email");
        sessionStorage.removeItem("spotbox-password");
        sessionStorage.removeItem("spotbox-confirmation-code");
        sessionStorage.removeItem("spotify_code_verifier");
        sessionStorage.removeItem("spotify_auth_state");
        router.replace("/home");
      });
  }, [clerkUserId, getToken, redirectUri, router, searchParams, variant]);

  useEffect(() => {
    if (variant !== "GUEST") {
      return;
    }

    const authCode = searchParams.get("code");
    const authState = window.sessionStorage.getItem("spotify_auth_state");
    const spotifyAuthError = searchParams.get("error");
    const codeVerifier = window.sessionStorage.getItem("spotify_code_verifier");

    if (spotifyAuthError) {
      // TODO: Handle error logging here, if needed.
      return;
    } else if (!authCode) {
      return;
    } else if (!authState) {
      return;
    } else if (!codeVerifier) {
      return;
    }

    fetch("https://accounts.spotify.com/api/token", {
      body: new URLSearchParams({
        client_id: CLIENT_ID,
        code: authCode,
        code_verifier: codeVerifier,
        grant_type: "authorization_code",
        redirect_uri: redirectUri,
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "POST",
    })
      .then((response) => response.json())
      .then((data: SpotifyTypes.AccessToken | { error: string; error_description: string }) => {
        if (!("error" in data) && !("error_description" in data)) {
          const {
            access_token: accessToken,
            refresh_token: refreshToken,
            expires_in: expiresIn,
          } = data;

          const expirationTimeString = dateToFormattedISOString(
            new Date(Date.now() + expiresIn * 1000 - 20),
          );
          sessionStorage.setItem("spotify_access_token", accessToken);
          sessionStorage.setItem("spotify_refresh_token", refreshToken);
          sessionStorage.setItem("spotify_expiration", expirationTimeString);
        }
      })
      .finally(() => {
        sessionStorage.removeItem("spotbox-email");
        sessionStorage.removeItem("spotbox-password");
        sessionStorage.removeItem("spotbox-confirmation-code");
        sessionStorage.removeItem("spotify_code_verifier");
        sessionStorage.removeItem("spotify_auth_state");
        router.replace("/");
      });
  }, [redirectUri, router, searchParams, variant]);

  return <>{children}</>;
}
