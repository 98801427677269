"use client";

import { ChevronDownIcon, ChevronUpIcon, PlusCircleIcon } from "@components/icons";
import { Card, CardDescription, CardTitle } from "@components/ui/card";
import type { RocolaTrack } from "@rocola/core/typings";
import Image from "next/image";
import type { MouseEventHandler } from "react";
import styles from "./SongCard.module.css";

interface GeneralProps {
  track: RocolaTrack;
}

interface VoteSongCardProps extends GeneralProps {
  downvotes: number;
  onDownvote: () => void;
  onUpvote: () => void;
  upvotes: number;
  variant: "vote";
}

interface AddSongCardProps extends GeneralProps {
  onAdd: () => void;
  variant: "add";
}

interface SelectSongCardProps extends GeneralProps {
  isSelected: boolean;
  onSelect: MouseEventHandler<HTMLButtonElement>;
  variant: "select";
}

/**
 * There are three different functionalities that this component takes on.
 * Hence, there are three different classes of props that this component will take on.
 */
type Props = VoteSongCardProps | AddSongCardProps | SelectSongCardProps;

export default function SongCard(props: Props) {
  const { track } = props;

  return (
    <Card className="song_card transition-transform-background">
      <div className="w-1/4 p-0 flex-shrink-0">
        <Image alt={track.title} height={100} src={track.albumImageUrl} width={100} />
      </div>
      <div className={styles.content_container}>
        <CardTitle className={styles.title_heading}>{track.title}</CardTitle>
        <CardDescription className={styles.artists_heading}>
          {track.artistName}
          {/* {track.artist.join(", ")} */}
        </CardDescription>
      </div>
      <div className={styles.button_container}>
        {props.variant === "vote" && (
          <>
            <div className="text-xl min-[360px]:text-3xl text-rocola-darkblue pr-3">
              {props.upvotes - props.downvotes}
            </div>
            <div className="flex flex-col">
              <button onClick={props.onUpvote}>
                <ChevronUpIcon
                  className="w-7 h-7 min-[360px]:w-9 min-[360px]:h-9 xs:w-10 xs:h-10 stroke-rocola-darkblue"
                  strokeWidth={2}
                />
              </button>
              <button onClick={props.onDownvote}>
                <ChevronDownIcon
                  className="w-7 h-7 min-[360px]:w-9 min-[360px]:h-9 xs:w-10 xs:h-10 stroke-rocola-darkblue"
                  strokeWidth={2}
                />
              </button>
            </div>
          </>
        )}
        {props.variant === "add" && (
          <button className="icon_button" onClick={props.onAdd}>
            <PlusCircleIcon strokeWidth={1} />
          </button>
        )}
        {props.variant === "select" && (
          <button
            className={`rounded-full ${
              props.isSelected ? "bg-rocola-blue" : "bg-none"
            } border sm:border-2 border-rocola-blue transition-all w-8 h-8 sm:w-11 sm:h-11`}
            onClick={props.onSelect}
          />
        )}
      </div>
    </Card>
  );
}
