"use client";

import { useEffect } from "react";

export default function LoadingIcon({
  size = 75,
  color = "white",
  speed = 1.5,
  ...props
}: {
  size?: string | number | undefined;
  color?: string | number | undefined;
  speed?: string | number | undefined;
  stroke?: string | number | undefined;
  "bg-opacity"?: string | number | undefined;
}) {
  useEffect(() => {
    async function getLoader() {
      const { waveform } = await import("ldrs");
      waveform.register();
    }
    getLoader();
  }, []);
  return <l-waveform color={color} size={size} speed={speed} {...props}></l-waveform>;
}
