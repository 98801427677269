import { createHash } from "crypto";
import type { RocolaSongQueue, RocolaVotableTrack } from "@rocola/core/typings";
import { Track } from "@rocola/core/typings/spotify";

/**
 * Randomly generates a 6-character id.
 *
 * @returns A 6-character string to represent the jukebox's ID.
 */
export function generateJukeboxId(): string {
  return generateRandomString(6).toUpperCase();
}

/**
 * Randomly generates a string containing only letters and numbers.
 *
 * @param length - The length of the string to be generated.
 * @returns The generated string.
 */
export function generateRandomString(length: number): string {
  let text = "";
  let dictionary = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < length; i++) {
    text += dictionary.charAt(Math.floor(Math.random() * dictionary.length));
  }
  return text;
}

/**
 * Generates a hashed representation of the provided code verifier using the SHA256 algorithm.
 * @see https://developer.spotify.com/documentation/web-api/tutorials/code-pkce-flow
 *
 * @param codeVerifier - The PKCE code verifier for the code challenge to be generated.
 * @returns The generated code challenge string.
 */
export function generateCodeChallenge(codeVerifier: string): string {
  const hash = createHash("sha256");
  hash.update(codeVerifier);
  const digest = hash.digest("base64");

  return digest.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

/**
 * Generates a URI that directs users to an external Spotify authorization page.
 *
 * @param client_id - The string ID representing the app on Spotify's Web API.
 * @param redirect_uri - The redirect uri of the app provided to Spotify Web API.
 *
 * @returns An object containing the redirect URI and its corresponding PKCE code verifier, PKCE code challenge, and auth state.
 */
export function createAuthorizationURI(client_id: string, redirect_uri: string) {
  const state = generateRandomString(16);
  let codeVerifier = generateRandomString(128);
  const code_challenge = generateCodeChallenge(codeVerifier);

  return {
    destination: `https://accounts.spotify.com/authorize?${new URLSearchParams({
      client_id,
      response_type: "code",
      redirect_uri,
      code_challenge_method: "S256",
      code_challenge,
      scope:
        "user-modify-playback-state user-read-playback-state user-top-read user-read-currently-playing user-read-recently-played playlist-read-private",
      state,
      show_dialog: "true",
    })}`,
    codeVerifier,
    code_challenge,
    state,
  };
}

/**
 * Formats expires_at returned from Spotify tokens
 * @param date - The Date object representing the date at which the access token expires at.
 */
export function dateToFormattedISOString(date: Date) {
  return date.toISOString().slice(0, 19).replace("T", " ").replace(/-/g, "/");
}

export const convertResultsToSong = (tracks: Track[]): RocolaSongQueue =>
  tracks.map(
    (track) =>
      ({
        title: track.name,
        artistName: track.artists[0].name,
        albumImageUrl: track.album.images[0].url,
        albumName: track.album.name,
        id: track.id,
        spotifyUri: track.uri,
        upVotes: 0,
        downVotes: 0,
      }) as RocolaVotableTrack,
  );
