import { RocolaCurrentlyPlayingTrack, RocolaVotableTrack } from "@rocola/core/typings";
import { generateJukeboxId } from "./functions";
import * as SpotifyTypes from "@rocola/core/src/typings/spotify";

/**
 * Generates a URI that directs users to an external Spotify authorization page.
 *
 * @param userId - The string ID representing the app on Spotify's Web API.
 * @param redirect_uri - The redirect uri of the app provided to Spotify Web API.
 *
 * @returns An object containing the redirect URI and its corresponding PKCE code verifier, PKCE code challenge, and auth state.
 */
export async function createSpotifyToken(
  userId: string,
  token: string | null,
  code: string,
  codeVerifier: string,
  origin: string,
  state: string,
) {
  const response = await fetch(
    process.env.NEXT_PUBLIC_API_URL + `/users/${userId}/spotify/tokens`,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        code,
        codeVerifier,
        origin,
        state,
      }),
    },
  );
  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }
  return response.json();
}

/**
 * Enqueues the top song of the jukebox queue, if any, to the host's Spotify Player.
 *
 * @param jukeboxId - The ID of the targeted jukebox.
 * @param token - The Spotify access token required to perform the transaction
 * @param controller - the `AbortController` for rejecting duplicate requests.
 */
export async function enqueueToSpotify(
  jukeboxId: string,
  token: string | null,
  signal?: AbortSignal,
): Promise<number> {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/jukeboxes/${jukeboxId}/spotify/queue`,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      signal,
    },
  );

  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }
  return response.status;
}

/**
 * Calls the API endpoint that searches for tracks that match the given query.
 *
 * @param query - The search query for the song
 * @param jukeboxId - The ID of the jukebox to receive query results
 * @param token - The Clerk JWT token associated with a signed in user
 * @param spotifyToken - The guests's Spotify access token used in place of the host's.
 * @returns A Promise that resolves with song results from the search query.
 */
export async function searchTracks(
  query: string,
  jukeboxId: string,
  token: string | null,
  spotifyToken: string | null,
): Promise<SpotifyTypes.Track[]> {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/jukeboxes/${jukeboxId}/spotify/songs?query=${query}${spotifyToken ? `&spotifyToken=${spotifyToken}` : ""}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );

  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }

  return response.json();
}

/**
 * Calls API endpoint to retrieves recommended tracks for the host of the jukebox.
 *
 * @param jukeboxId - The ID of the jukebox to receive song recommendations
 * @param spotifyToken - The guests's Spotify access token used in place of the host's.
 * @returns A Promise that resolves with recommended songs.
 */
export async function getRecommendedTracks(
  jukeboxId: string,
  spotifyToken?: string,
): Promise<SpotifyTypes.Track[]> {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/jukeboxes/${jukeboxId}/spotify/songs-recommended?${spotifyToken ? `&spotifyToken=${spotifyToken}` : ""}`,
    {
      method: "GET",
    },
  );

  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }

  return response.json();
}

/**
 * Calls the API endpoint to retrieve recently played songs (on Spotify) by the host of the jukebox.
 *
 * @param jukeboxId - The ID of the jukebox to receive recently played songs
 * @param token - The Clerk JWT token associated with a signed in user
 * @returns A Promise that resolves with the recently played tracks
 */
export async function getRecentlyPlayedTracks(jukeboxId: string, token: string | null) {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/jukeboxes/${jukeboxId}/spotify/songs-recently-played`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );

  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }

  return response.json() as Promise<SpotifyTypes.Track[]>;
}

/**
 * Calls the API endpoint to update the currently playing song on the host's Spotify account.
 *
 * @param jukeboxId - The ID of the jukebox whose currently playing track will be updated.
 * @param token - The Clerk JWT token associated with a signed in user
 */
export async function updateSpotifyPlayingTrack(
  jukeboxId: string,
  token: string | null,
  signal?: AbortSignal,
): Promise<void> {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/jukeboxes/${jukeboxId}/spotify/currently-playing`,
    {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
      signal: signal,
    },
  );

  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }
}

/**
 * Calls the API endpoint to get all public and private playlists of the host associated with the jukebox.
 *
 * @param jukeboxId - The ID of the jukebox whose host's playlists will be retrieved.
 * @param token - The Clerk JWT token associated with a signed in user
 * @returns A Promise that resolves to the search results of the query.
 */
// export async function getPlaylists(jukeboxId: string, token: string | null) {
//   // TODO
// }

/**
 *
 * @param inputSong -
 * @param jukeboxId -
 * @param token - The Clerk JWT token associated with a signed in user
 * @returns
 */
export async function searchPlaylistSongs(
  inputSong: string,
  jukeboxId: string,
  token: string | null,
) {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/jukeboxes/${jukeboxId}/spotify/playlists`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        songInput: inputSong,
      }),
    },
  );
  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }
  return response.json();
}

/**
 * Refreshes the access token attached to the jukebox associated with the provided ID.
 *
 * @param jukeboxId - The ID of the jukebox whose access & refresh tokens will be updated.
 * @param token - The Clerk JWT token associated with a signed in user
 */
export async function updateSpotifyToken(jukeboxId: string, token: string | null): Promise<void> {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/jukeboxes/${jukeboxId}/spotify/refresh-token`,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );
  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }
}

/**
 * Retrieves the song queue of the jukebox associated with the given jukeboxId.
 *
 * @param token - The Clerk JWT token associated with a signed in user
 * @returns A Promise that resolves to the jukebox
 */
export async function getJukebox(token: string | null): Promise<{
  jukeboxName: string;
  jukeboxId: string;
}> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/jukeboxes", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch jukebox");
  }

  return response.json();
}

/**
 * Retrieves the song queue of the jukebox associated with the given jukeboxId.
 *
 * @param jukeboxId - The ID of the jukebox whose song queue will be returned.
 * @returns A Promise that resolves to the current song queue of the jukebox or undefined if it does not exist.
 */
export async function getQueue(jukeboxId: string): Promise<RocolaVotableTrack[]> {
  const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/jukeboxes/${jukeboxId}/queue`, {
    method: "GET",
  });

  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }

  return response.json();
}

/**
 * Retrieves the representation for the currently playing track for the jukebox.
 *
 * @param jukeboxId - The ID of the jukebox receiving the object for the currently playing track.
 * @returns A Promise that resolves to the object representing the currently playing track for the jukebox.
 */
export async function getCurrentlyPlaying(
  jukeboxId: string,
  signal?: AbortSignal,
): Promise<RocolaCurrentlyPlayingTrack | undefined> {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/jukeboxes/${jukeboxId}/currently-playing`,
    {
      method: "GET",
      signal,
    },
  );

  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }

  return response.json();
}

/**
 * Creates a virtual jukebox for the user.
 *
 * @param jukeboxName - The name of the jukebox to be created.
 */
export async function createJukebox(jukeboxName: string, token: string | null) {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/jukeboxes", {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({
      jukeboxId: generateJukeboxId(),
      jukeboxName,
    }),
  });
  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }
}

/**
 * Retrieves the song queue of the jukebox associated with the given jukeboxId.
 *
 * @param token - The Clerk JWT token associated with a signed in user
 * @param jukeboxId - The ID of the jukebox to receive query results
 * @returns A Promise that resolves to the jukebox
 */
export async function hasTokens(jukeboxId: string | null): Promise<boolean> {
  const response = await fetch(
    process.env.NEXT_PUBLIC_API_URL + `/jukeboxes/${jukeboxId}/spotify/tokens`,
    {
      method: "GET",
    },
  );

  if (!response.ok) {
    throw new Error("Failed to fetch jukebox");
  }

  return response.json();
}

/**
 * Retrieves the song queue of the jukebox associated with the given jukeboxId.
 *
 * @param token - The Clerk JWT token associated with a signed in user
 * @param jukeboxId - The ID of the jukebox to receive query results
 * @returns A Promise that resolves to the jukebox
 */
export async function updateJukebox(
  jukeboxId: string,
  token: string | null,
  updates: {
    name?: string;
  },
): Promise<boolean> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + `/jukeboxes/${jukeboxId}`, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(updates),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch jukebox");
  }

  return response.json();
}
